<template>
    <div class="pic-block">
        <div ref="wrap" class="pic-tab-header-wrap">
            <div ref="tabHeader" class="pic-tab-header flex" :class="{fixed: fixed, disabledFixed: disabledFixed}">
                <div :class="['tab-item', {active: tabIndex === idx}]" @click="changeTab(idx)"
                    v-for="(item,idx) in tabList" :key="idx"
                >{{item.name}}</div>
            </div>
        </div>
        
        <div class="pic-tab-body" ref="picBody">
            <div v-show="tabIndex===0" class="pic-wrap">
                <div class="pic-wrap-left-slot">
                    <div class="pic-wrap-left" :class="{fixed: fixed, disabledFixed: disabledFixed}">
                        <div :class="{active: imgTabIndex === 0}" @click="changeImgIndex(0)">
                            <i></i><span>标高图</span>
                        </div>
                        <div class="line"></div>
                        <div :class="{active: imgTabIndex === 1}" @click="changeImgIndex(1)">
                            <i></i><span>素材图</span>
                        </div>
                    </div>
                </div>
                <div class="pic-wrap-main">
                    <div class="name ellipsis">{{item.name}}</div>
                    <div v-if="item.dimension" class="size">
                        <label v-for="(size,idx) in item.dimension" :key="idx">{{size}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                    </div>
                    <div class="tit" ref="bg0">标高图</div>
                    <div v-if="item.photo_size && item.photo_size.length>0" class="img-list">
                        <img v-for="(item,idx) in item.photo_size" :key="idx" 
                            :src="(imgUrl || '')+item+'!width_800px'">
                    </div>
                    <div v-else class="img-list">
                        <img src="../../assets/images/desgin/zwt.png" alt="">
                    </div>
                    <div class="tit" ref="sc0">素材图</div>
                    <div v-if="item.photo_story && item.photo_story.length>0" class="img-list">
                        <img v-for="(item,idx) in item.photo_story" :key="idx" 
                            :src="(imgUrl || '')+item+'!width_800px'">
                    </div>
                    <div v-else class="img-list">
                        <img src="../../assets/images/desgin/zwt.png" alt="">
                    </div>
                </div>
                
            </div>
            <div v-show="tabIndex>0" class="pic-wrap">
                <div class="pic-wrap-left-slot">
                    <div class="pic-wrap-left" :class="{fixed: fixed, disabledFixed: disabledFixed}">
                        <div :class="{active: imgTabIndex === 0}" @click="changeImgIndex(0)">
                            <i></i><span>标高图</span>
                        </div>
                        <div class="line"></div>
                        <div :class="{active: imgTabIndex === 1}" @click="changeImgIndex(1)">
                            <i></i><span>素材图</span>
                        </div>
                    </div>
                </div>
                <div v-for="(item,idx) in item.accessory" :key="idx" class="pic-wrap-main">
                    <div v-if="tabIndex===(idx+1)">
                        <div class="name ellipsis">{{item.name}}</div>
                        <div v-if="item.size" class="size">{{item.size.len}}mm*{{item.size.wlen}}mm*{{item.size.height}}mm</div>
                        <div class="tit" :ref="'bg' + (idx + 1)">标高图</div>
                        <div v-if="item.photo_size && item.photo_size.length>0" class="img-list">
                            <img v-for="(item,idx) in item.photo_size" :key="idx" 
                                :src="(imgUrl || '')+item+'!width_800px'">
                        </div>
                        <div v-else class="img-list">
                            <img src="../../assets/images/desgin/zwt.png" alt="">
                        </div>
                        <div class="tit" :ref="'sc' + (idx + 1)">素材图</div>
                        <div v-if="item.photo_story && item.photo_story.length>0" class="img-list">
                            <img v-for="(item,idx) in item.photo_story" :key="idx" 
                                :src="(imgUrl || '')+item+'!width_800px'">
                        </div>
                        <div v-else class="img-list">
                            <img src="../../assets/images/desgin/zwt.png" alt="">
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </div>
</template>

<script>
import { throttle } from 'lodash'
import { IMG_URL_PRE } from "@/config";
export default {
  props: ['item'],
  data() {
    return {
        imgUrl: IMG_URL_PRE,
        tabList:[
            {name:"主作品"},
        ],
        tabIndex:0,
        imgTabIndex: 0,
        fixed: false,
        disabledFixed: false
    };
  },
  created() {
      if (this.item.accessory && this.item.accessory.length>0) {
            this.item.accessory.forEach(e => {
                this.tabList.push({name:e.name});
            });
        }
      this.scroll = throttle(this.scroll, 50)
  },
  methods: {
    goto(path){
        this.$router.push(path);
    },
    toDetail(id) {
    //   this.$router.push(`/comp/${this.newType}/${id}`);
    },
    changeImgIndex(i) {
        const scroller = this.$parent.$refs.scroller
        this.imgTabIndex = i
        // console.log(this.$refs[`bg${this.tabIndex}`].offsetTop)
        if (i === 0) {
            if (this.tabIndex > 0) {
                scroller.scrollTop = this.$refs[`bg${this.tabIndex}`][0].offsetTop - 110
            } else {
                scroller.scrollTop = this.$refs[`bg${this.tabIndex}`].offsetTop - 110
            }
            
        } else {
            if (this.tabIndex > 0) {
                scroller.scrollTop = this.$refs[`sc${this.tabIndex}`][0].offsetTop - 110
            } else {
                scroller.scrollTop = this.$refs[`sc${this.tabIndex}`].offsetTop - 110
            }
        }
    },
    scroll(e) {
        
        const scroller = e.target
        const wrap = this.$refs.wrap
        const tabHeader = this.$refs.tabHeader
        // console.log(scroller.scrollTop);
        // console.log(scroller.scrollTop, wrap.offsetTop)
        // 主副tab滑到顶部fix
        if (scroller.scrollTop > wrap.offsetTop - (0)) {
            if (!this.fixed) {
                this.fixed = true
            }
        } else {
            if (this.fixed) {
                this.fixed = false
            }
        }
        const picBody = this.$refs.picBody;
        // 素材图向上划出时隐藏tab
        if (scroller.scrollTop > picBody.offsetTop + picBody.offsetHeight - 470) {
            if (!this.disabledFixed) {
                this.disabledFixed = true
            }
        } else {
            if (this.disabledFixed) {
                this.disabledFixed = false
            }
        }
        //标高图素材图tab切换
        const sc = this.tabIndex === 0?this.$refs.sc0:this.$refs[`sc${this.tabIndex}`][0]
        if (scroller.scrollTop > sc.offsetTop - 110) {
            if (this.imgTabIndex !== 1) {
                this.imgTabIndex = 1
            }
        } else {
            if (this.imgTabIndex !== 0) {
                this.imgTabIndex = 0
            }
        }
    },
    changeTab: function (i) {// 选项卡
        this.tabIndex = i
        const scroller = this.$parent.$refs.scroller
        const wrap = this.$refs.wrap
        // console.log(wrap.offsetTop)
        // scroller.scrollTop = wrap.offsetTop
        setTimeout(() => {
            scroller.scrollTop = wrap.offsetTop
        },100)
    },

  }
};
</script>

<style scoped lang="less">
.pic-block {
    min-width: 1110px;
    background: #FFFFFF;
    padding-bottom: 30px;
    padding-top: 24px;
    >div {
        width: 1100px;
        margin: 0 auto; 
    }
    .pic-tab-header-wrap {
        height: 96px;
        margin-bottom: 5px;
    }
    .pic-tab-header {
        width: 1100px;
        padding-left: 113px;
        background-color: #E6E6E6;
        // display: flex;
        font-size: 20px;
        
        &.fixed {
            position: fixed;
            top: 120px;
            z-index: 999;
            
        }
        &.disabledFixed {
            position: relative!important;
            top: initial;
        }
        .tab-item {
            width:172px;
            height:96px;
            background-color: #E6E6E6;
            height: 96px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            cursor: pointer;
            &.active {
                background-color: #fff; 
                &.active:after {
                    position: absolute;
                    top: 0;
                    content: '';
                    display: block;
                    width: 100%;
                    height: 7px;
                    background:linear-gradient(180deg,rgba(131,98,228,1) 0%,rgba(80,116,238,1) 100%);
                }
            }
            
        }
    }
    .pic-tab-body {
        background: #F5F5F5;
        box-shadow:0px 3px 6px rgba(0,0,0,0.16);
        .pic-wrap {
            padding: 40px 0 ;
            color: #808080;
            display: flex;
            .pic-wrap-left-slot {
                width: 98px;
                margin-left: 68px;
                margin-right: 64px;
            }
            .pic-wrap-left {
                // position: absolute;
                // left: 68px;
                // top: 85px;
                
                &.fixed {
                    position: fixed;
                    top: 261px;
                }
                &.disabledFixed {
                    position: relative!important;
                    top: initial;
                }
                .line {
                    height: 246px;
                    width: 0;
                    border-right: 1px solid #E9B155;
                    position: relative;
                    left: 11px;
                    cursor: initial;
                }
                >div {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    color: #808080;
                    cursor: pointer;
                    &.active {
                        color: #E6A23C;
                        i {
                            background:linear-gradient(180deg,rgba(219,162,76,1) 0%,rgba(250,196,96,1) 100%);
                        }
                    }
                    i {
                        width:24px;
                        height:24px;
                        background:#707070;
                        border-radius:50%;
                        opacity:1;
                        margin-right: 26px;
                    }
                }
            }
            .pic-wrap-main {
                // margin-left: 64px;
                .name {
                    font-size: 22px;
                    font-weight: bold;
                }
                .size {
                    margin-bottom: 22px;
                }
                .tit {
                    margin-bottom: 10px;
                    font-weight: bold;
                }
                .img-list {
                    display: flex;
                    flex-direction: column;
                    img {
                        display: block;
                        width: 750px;
                        margin-bottom: 47px;
                    }
                }
            }
        }
    }
}
</style>